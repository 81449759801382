

.ant-checkbox-group {
	display: grid;
	max-width: 100%;
	grid-template-columns: repeat(auto-fill, minmax(20%, max-content));

	.ant-checkbox-group-item {
		align-items: center;
	}
}

.quill {
	background: white;
	.ql-toolbar.ql-snow {
		border-radius: var(--less-round) var(--less-round) 0 0;
		border-bottom: none;
	}
	.ql-container.ql-snow {
		border-radius: 0 0 var(--less-round) var(--less-round);
		border-top: 1px solid #ccc !important;
		overflow: visible;
		transition: all 0.2s;

		&:hover{
			border-color: var(--sec-fg) !important;
		}
		&:focus-within{
			border-color: var(--sec-fg) !important;
			box-shadow: 0 0 0 2px rgb(20 100 195 / 20%) !important;
		}
	}
}

.ant-input {
	border-radius: var(--less-round);
	&:hover{
		border-color: var(--sec-fg);
	}
	&:focus{
		border-color: var(--sec-fg);
		box-shadow: 0 0 0 2px rgb(20 100 195 / 20%);
	}

}

.ant-input-number {
	width: 100%;
	border-radius: var(--less-round);
	&:hover{
		border-color: var(--sec-fg);
	}
	&.ant-input-number-focused{
		border-color: var(--sec-fg);
		box-shadow: 0 0 0 2px rgb(20 100 195 / 20%);
	}

}