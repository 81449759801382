
@import "./styles/Button.scss";
@import "./styles/Input.scss";


:root,
::after,
::before {
	--prim-bg: #E8E8E3;
	--sec-bg: #F5F5F5;
	
	//--sec-bg-focus-light: #454545;
	//--sec-bg-focus-dark: #282828;

	// --tert-bg: #000;

	--prim-fg: rgba(255,255,255,0.65);
	--prim-fg-focus: rgba(255,255,255,0.85);
	--sec-fg: #2b4570;
	--sec-fg-focus: #2b45D0;
	--tert-fg: #3891A6;
	--tert-fg-focus: #58A1D0;
	--light-text: #85859F;

	--selector: #1770BD;
	--selector-light: #39A7F1;

	--focus: #2b457019;
	--focus-active: #2b457030;

	--shadow: #33333340;
	--shadow-border: #CCCAC8;

	--grey: #888;
	--red: #711;
	--yellow: #DF9300;
	--blue: #39A7F1;
	--purple: #9254DE;
	--green: #339933;

	--ant-primary-color: var(--selector);

	--round: 0.6vmin;
	--less-round: 0.3vmin;
}

* {
	box-sizing: border-box;
}

body {
	margin: 0;
	font-family: 'Roboto';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
  
code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
  
.row {
	display: flex;
	align-items: center;
	gap: inherit;

	.row-right {
		margin-left: auto;
	}
	.row-left {
		margin-right: auto;
	}
}

.col {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: inherit;

	.col-top {
		margin-bottom: auto;
	}
	.col-bottom {
		margin-top: auto;
	}
}

a {
	color: var(--sec-fg);
}

h3 {
	font-size: 14px;
	font-weight: 600;
	margin: 0;
	margin-bottom: 1vmin;
}
p {
	margin: 8px 0;
	font-size: 16px;
	font-weight: 400;
}
.light-text {
	color: var(--light-text);
}

.divider{
	background: var(--prim-bg);
	width: 100%;
	height: 1px;
}
.center-box {
	display: flex;
	align-items: center;
	justify-content: center;
}

.side-note {
	color: var(--light-text);

	display: flex;
	gap: 0.5vmin;
	align-items: flex-start;
	padding: 0.5vmin 0;

	svg {
		margin-top: 2px;
	}

	span {
		font-size: 12px;
	}
}