
.directory-body {
	display: flex;
	flex-direction: column;
	gap: 1vmin;
}

.directory-tile {
	overflow: hidden;
	padding: 1vmin 3vmin;
	height: fit-content;

	.directory-header {
		margin-bottom: 2vmin;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;

		.left {
			padding-right: 25px;
		}

		.right {
			display: flex;
			flex-direction: column;
			align-items: flex-end;

			.ant-btn:first-child {
				margin-top: 8px;
			}

			.ant-btn:not(:last-child) {
				margin-bottom: 8px;
			}
		}

		h2 {
			color: var(--tert-fg);
			margin: 0.5vmin 0;
		}

		.directory-desc {
			color: var(--light-text);
			font-size: 16px;

			* {
				margin: 0;
				font-size: 16px;
				color: var(--light-text);
			}

			h1 {
				font-size: 20px;
			}

			h2 {
				font-size: 17px;
			}

			h3 {
				font-size: 14px;
			}

			p {
				font-size: 16px;
			}
		}
	}

	.directory-attr-row {
		gap: 4vmin;
		align-items: start;

		.directory-attr {
			h3 {
				margin: 0;
			}
		}
	}
}

.edit-header {
	margin: 16px 0;
	margin-left: -12px;
}

.edit-directory-body {

	.tile-group{
		gap: 2.5vmin;
		width: 100%;

		.row {
			width: 100%;
			flex: 1 1 auto;
		}
	}
	

	.content-tile {
		display: flex;
		flex-direction: column;
		padding: 2vmin 3vmin;
		gap: 2vmin;
		height: fit-content;

		.ant-form-item {
			margin: 0;
		}
	}

	.input-table-row {

		gap: 1vmin;
		width: fit-content !important;

		&.input-table-entry {
			margin: 0.5vmin 0;
			border: 1px solid var(--shadow-border);
			padding: 0.5vmin 0;
		}

		&.input-table-entry {
			>:nth-child(1){
				padding-left: 2vmin;
			}
			>:nth-child(3){
				padding-right: 1vmin;
			}
		}
		
		>:nth-child(1){
			width: 60px;
		}
		>:nth-child(2){
			width: 250px;
		}
 		// >:nth-child(3){

		// }
	}

	.directory-location-tiles {
		align-items: start;
	}

	.coord-inputs {
		gap: 1vmin;
		>div {
			flex: 1 0 0;
			align-items: flex-start;


		}
	}
}

.google-map {
	height: 500px;
	.map-pin {
		font-size: 32px;
		color: var(--sec-fg);
	}
}