

.nav-container {

	height: 100vh;
	
	background: var(--sec-bg);
	box-shadow: 0 0.5vmin 0.7vmin 0 var(--shadow);

	z-index: 1;

	display: flex;
	flex-direction: column;
	gap: 2vmin;
	padding: 2vmin;

	a{
		text-decoration: none;

		.nav-button{
			width: 11.5vmin;
			height: 11.5vmin;
			border-radius: var(--round);
			
			cursor: pointer;

			display: flex;
			gap: 0.82vmin;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			text-align: center;
			font-size: 2.47vmin;
			padding: 0.82vmin;

			span{
				font-size: 1.85vmin;
				line-height: 1.85vmin;
			}

			&:hover {
				background: var(--focus);
			}

			&.selected{
				background: var(--focus-active);
			}
		}
	}
	
}