// .ant-btn{
// 	display: flex;
// 	gap: 5.5px;
// 	align-items: center;
// 	justify-content: center;
// 	width: fit-content;
// 	// svg{
// 	// 	margin-right: 5.5px;
// 	// }
// }
// .ant-btn.ant-btn-icon-only{
// 	padding:4px !important;
// }

// .ant-btn.ant-btn-default{
// 	background-color: var(--sec-bg-focus-light);
// 	color: var(--prim-fg);
// 	border: 0;
// 	padding: 4px 15px;
// 	--antd-wave-shadow-color:  var(--selector);

// 	>span{
// 		position: relative;
// 		top: -0.5px;
// 	}

// 	&:hover{
// 		color: var(--prim-fg-focus);
// 	}

// }

.ant-btn.ant-btn-primary{
	border: 0;
	box-shadow: none;
}
// .ant-btn[disabled]:not(.ant-btn-text), .ant-btn[disabled]:not(.ant-btn-text):hover {
// 	background: var(--sec-bg-focus-dark);
// 	color: var(--sec-fg);
// 	span{
// 		color: var(--sec-fg);
// 	}
// }

// .ant-btn.ant-btn-ghost{
// 	background-color: transparent;
// 	color: var(--prim-fg);
// 	box-sizing: border-box;
// 	border: 2px solid var(--sec-bg-focus-light);
// 	padding: 4px 15px;
// 	--antd-wave-shadow-color:  var(--selector);

// 	>span{
// 		position: relative;
// 		top: -1.5px;
// 	}

// 	&:hover{
// 		color: var(--prim-fg-focus);
// 		border-color: var(--sec-bg-focus-light);
// 	}

// }





// .ant-btn.ant-btn-link{
// 	font-weight: 600;
// 	color: var(--selector);
// 	--antd-wave-shadow-color:  var(--selector);
// 	span{
// 		color: var(--selector);
// 	}
// 	&:active{
// 		color: var(--selector-light);
// 	}

// }


// .ant-btn .anticon.anticon-plus > svg, .ant-btn .anticon.anticon-minus > svg {
//     shape-rendering: auto;
// }

// .text-btn{
// 	color: var(--prim-fg);
// }

// .ant-btn-text{
// 	color: var(--sec-fg);
// 	padding: 8px;
// 	color: var(--sec-fg);
// 	font-size: 24px;
// 	svg{
// 		color: var(--sec-fg);
// 	}

// 	&:active{
// 		background: var(--focus-active) !important;
// 		color: var(--sec-fg);
// 		svg{
// 			color: var(--sec-fg);
// 		}
// 	}
// 	&:hover{
// 		background: var(--focus) !important;
// 		color: var(--sec-fg);
// 		svg{
// 			color: var(--sec-fg);
// 		}
// 	}
// }

// .ant-btn-text[disabled], .ant-btn-text[disabled]:hover {
// 	color: var(--sec-fg);
// 	span, svg{
// 		color: var(--sec-fg);
// 	}
// }

.ant-btn-text.content-btn{
	display: flex;
	align-items: center;
	color: var(--sec-fg);
	margin: 0.1vmin;
	padding: 0;
	border: 0;
	.ant-btn-icon {
		margin-bottom: 2px;
	}

	&:hover{
		color: var(--sec-fg-focus) !important;
		background: none !important;
	}
	&:active{
		color: var(--sec-fg) !important;
	}
}