.map-result .attribute {
    margin-right: 24px;
}

.map-result .attribute .content p {
    font-family: "Roboto";
    font-style: "normal";
    font-weight: 400;
    font-size: "14px";
    line-height: "16px";
    color: #242424;
    margin-top: 0 !important;
}

.map-result .description-attribute .content p {
    font-family: "Roboto";
    font-style: "normal";
    font-weight: 400;
    font-size: "14px";
    line-height: "16px";
    color: #777777;
}

.map-result .attribute .label {
    font-family: "Roboto";
    font-style: "normal";
    font-weight: bold;
    font-size: "16px";
    line-height: "19px";
    color: #242424;
}

.box-tag {
    border-width: 1px !important;
    border-radius: var(--round) !important;
    border-style: solid !important;
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 2px 5px;
}

.icon-tag {
    margin-bottom: 5px;
    margin-left: 5px;
    padding: 3px;
}
