
.header {
	background: var(--sec-bg);
	height: 9.4vmin;
	padding: 0 5.3vmin;
	box-shadow: 0 0.225vmin 0.45vmin 0 var(--shadow);

	.logo{
		width: 18.1vmin;
		height: 6.6vmin;
		background-color: var(--sec-fg);
		mask: url("../../assets/bs-logo-white.svg") no-repeat 50% 50%;
		mask-size: contain;
	}

	.user-dropdown {
		display: flex;
		align-items: center;
		gap: 0.82vmin;
		border-radius: var(--round);
		padding: 0.82vmin;
		color: var(--sec-fg);
		cursor: pointer;

		font-weight: 500;
		font-size: 2.5vmin;

		svg {
			font-size: 1.65vmin;
		}
		&:hover{
			background: var(--focus);
		}
		&:active {
			background: var(--focus-active);
		}
	}
}

