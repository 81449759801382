
.layout {
	overflow: hidden;

	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	position: relative;


	.layout-header {
		z-index: 1;
	}

	.layout-body {
		z-index: 0;
		height: 100%;
		width: 100%;
		align-items: start;

		.layout-content {
			display: flex;
			flex-direction: column;
			width: 100%;
			height: calc(100% - 9vmin);
			padding: 0 4.9vmin;
			background: var(--prim-bg);
			overflow-y: auto;
			
		}
	}
}


.ant-layout-sider {
    background-color: #407b82 !important;
}

.exit-button {
    margin-left: auto;
}


.content-header {
	display: flex;
	align-items: center;
	gap: 8px;
}

.content-body {

	padding: 0.41vmin;//allow shadows to bleed over the tile
	margin: -0.41vmin;

	margin-bottom: 4.2vmin;
}
.content-tile {
	background: var(--sec-bg);
	width: 100%;
	height: 100%;
	border-radius: var(--round);
	border: 0.1vmin solid var(--shadow-border);	

	overflow: visible;
	box-shadow: 0 0.1vmin 0.41vmin 0 var(--shadow);
	padding: 1.23vmin 2.46vmin;
}