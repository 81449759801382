

.report-body {
	margin-bottom: 0;
}

.report-container {
    display: flex;
    justify-content: center;
    margin: 2vmin 0;
	height: calc(100vmin - 4vmin - 9.4vmin);

	&:fullscreen{
		.expand {
			margin: 2vmin;
			color: var(--prim-fg);
			background: none;
			&:hover{
				color: var(--prim-fg-focus);
				background: none;
			}
			&:active{
				background: rgba(255,255,255,0.1);
			}
		}
	}

	.expand {
		font-size: 4vmin;
		display: flex;
		justify-content: center;
		align-items: center;
		width: fit-content;
		height: fit-content;
		border-radius: var(--round);
		padding: 1vmin;
		margin: 0 2vmin;
	}

	.report {
        aspect-ratio: 10 / 6.2;
        height: 100%;
		margin: 0;

		iframe {
            height: 100%;
            width: 100%;
            border: 0.2vmin var(--shadow-border) solid;
			border-radius: var(--round);

			box-shadow: 0px 0.1vmin 0.4vmin 0px var(--shadow);
        }
    }

	.noNav > .report {
        aspect-ratio: 10 / 5.7;
    }
}


.report.pageHeader {
    align-items: center;
}

    .report.pageHeader > #top-left-header {
        margin-bottom: -5px;
    }

        .report.pageHeader > #top-left-header > span {
            line-height: 28px;
        }

#report-logo {
    object-fit: contain;
    height: 48px;
    margin-right: 20px;
}

.reportMenu {
    min-height: 20px;
    border: 1px solid #8C8C8C;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    font-weight: bold;
    border-radius: var(--round);
}

    .reportMenu > span {
        color: #595959;
        margin-right: 5px;
    }

    .reportMenu > span, .reportMenu > .suffixIcon, .reportMenuOverlay .ant-dropdown-menu-item {
        line-height: 20px;
        font-size: 12px;
    }

#overview-report > .report-row {
  gap: 15px;
}

#overview-report > .report-row > * {
  flex: 1;
  margin-bottom: 10px;
}

.metric-group > .ant-card-head {
  min-height: 40px
}
.metric-group > .ant-card-head .ant-card-head-title {
  font-size: .9em;
  color: var(--blue);
  text-align: center;
}

.metric-group > .ant-card-body {
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
}

.metric-group > .ant-card-body > * {
  flex: 1;
}

.metric-group > .ant-card-body > *:not(:first-child) {
  margin-left: 20px;
}

.metric-group .metric > .ant-card-head {
  font-size: 1em;
  font-weight: 500;
  text-align: center;
  color: #FFF;
  background-color: var(--sec-fg);
}

.metric-group .metric > .ant-card-body {
  font-size: 1.5em;
  font-weight: 600;
  display: flex;
  justify-content: center;
  border: 1px solid var(--sec-fg);
}

#hestia-report {
  margin-bottom: 40px;
}

#hestia-report .hestia-data-tab {
  min-height: 400px;
  background-color: var(--sec-bg);
  padding: 20px;
}

#hestia-report .date-filter {
  margin-bottom: 15px;
}

#hestia-report .ant-tabs-nav {
  margin-top: 0;
}

.report-body .loading {
  min-height: 400px;
}

.hestia-report-container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.hestia-report-container > * {
  width: 48%;
  height: 300px;
  padding: 10px 0px;
}

.hestia-report-container > div > .ant-table-wrapper {
  overflow: auto;
  box-shadow: 0 2px 5px 0 grey;
  max-height: 100%;
}

.monthly-bar-chart-container {
  width: 100%;
  height: 400px;
}

.ant-table-expanded-row .ant-table-cell:before {
  display: none;
}

.ant-table-row-expand-icon-cell .ant-btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-table-row-expand-icon-cell .ant-btn-icon svg {
  height: .8em;
}
 
.report-body .report-error {
  min-height: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.report-body .report-error svg {
  height: 3em;
  color: var(--red);
  margin-bottom: 20px;
}

.report-body .report-error span {
  font-weight: 600;
}

.hestia-report-header {
  color: var(--sec-fg);
  font-size: 18px;
}

#hestia-page-view-table, #hestia-event-table {
  overflow: auto;
  max-height: 550px;
}