
.resources-header {
	gap: 8px;
}

.edit-header{
	margin: 16px 0;
	margin-left: -12px;
}

.resources-table {
	
	box-shadow: 0px 2px 4px 0px var(--shadow);
	border-radius: var(--round);
	overflow: hidden;
}

.page-title {
	font-size: 16px;
	margin: 16px 0;
	display: flex;
	align-items: center;
}