.sorticon-wrapper{
	display: flex;
	flex-direction: column;
	
	
	.sorticon-up{
		position: relative;
		top: 3px;
	}
	.sorticon-down{
		position: relative;
		bottom: 3px;
	}

	.sort-active{
		color: var(--selector);
	}
}